import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardMeta,
    Image,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Grid,
    Container,
    Icon,
    Menu,
    MenuItem,
} from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import Spinner from '../../spinner'
import { useTranslation } from 'react-i18next'

export default function OnlinePlayers({ inProgress, gameServer }) {
    const { t } = useTranslation()

    return (
        <Card fluid>
            <CardContent>
                <Table striped size="small" compact basic="very">
                    <TableBody>
                        {inProgress ? (
                            <TableRow>
                                <TableCell>
                                    <Spinner />
                                </TableCell>
                            </TableRow>
                        ) : gameServer.playerSessions.length > 0 ? (
                            gameServer.playerSessions.map(
                                (
                                    { player: { uuid, displayName, steamID } },
                                    idx
                                ) => (
                                    <TableRow key={uuid}>
                                        <TableCell
                                            textAlign="center"
                                            collapsing
                                        >
                                            {idx + 1}
                                        </TableCell>
                                        <TableCell textAlign="center">
                                            <Link
                                                target="_blank"
                                                to={`https://steamcommunity.com/profiles/${steamID}/`}
                                            >
                                                {displayName}
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                )
                            )
                        ) : (
                            <TableRow>
                                <TableCell textAlign="center" colSpan={3}>
                                    {t('i18n.No-players-found')}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    )
}
