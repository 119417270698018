import { TableRow, TableCell, Button } from 'semantic-ui-react'
import { useApiRequest } from '../../../hoc'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { openDialog } from '../../../../store/general-slice'
import { DialogKeys } from '../../../../constants'
import moment from 'moment'

export default function NoticeItem({ notice }) {
    const { t } = useTranslation()

    const [deleted, setDeleted] = useState(false)
    const deleteRequest = useApiRequest(
        ({ deleteGameServerNotice }) => deleteGameServerNotice,
        { onSuccess: () => setDeleted(true) }
    )
    const disabled = deleted || deleteRequest.inProgress

    const dispatch = useDispatch()

    return (
        <TableRow disabled={disabled}>
            <TableCell collapsing>{notice.number}</TableCell>
            <TableCell>{notice.message}</TableCell>
            <TableCell collapsing>
                {notice.lastUserMaintained.nickname}
            </TableCell>
            <TableCell collapsing>
                {notice.lastShowTimestamp
                    ? moment(notice.lastShowTimestamp).format('LLL')
                    : t('i18n.never')}
            </TableCell>
            <TableCell collapsing>
                {t(notice.enabled ? 'i18n.enabled' : 'i18n.disabled')}
            </TableCell>
            <TableCell collapsing>
                <Button
                    circular
                    icon="edit"
                    disabled={false}
                    onClick={() =>
                        dispatch(
                            openDialog({
                                dialogKey: DialogKeys.gameServerNoticeEdit,
                                notice,
                            })
                        )
                    }
                />
                <Button
                    circular
                    icon="delete"
                    negative
                    disabled={false}
                    onClick={() => deleteRequest.fetch(notice.uuid)}
                />
            </TableCell>
        </TableRow>
    )
}
