import { useSelector, useDispatch } from 'react-redux'
import { setTitle } from '../../store/general-slice'

export default function useTitle(newTitle) {
    const title = useSelector(({ general: { title } }) => title)

    const dispatch = useDispatch()
    const setTitleFunc = (newTitle) => dispatch(setTitle(newTitle))

    if (newTitle) {
        setTitleFunc(newTitle)
    }

    return [title, setTitleFunc]
}
