import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardMeta,
    Image,
    Icon,
} from 'semantic-ui-react'
import Spinner from '../../spinner'
import { useNow } from '../../hoc'
import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone'
import { intervalToString } from '../../../utils/date-time-utils'

export default function GeneralInfo({ gameServer }) {
    const now = useNow()
    const { t } = useTranslation()

    if (!gameServer) {
        return <Spinner />
    }

    const descriptionLines = gameServer.gameServerInfo.description.split('\\n')

    const lastUpdateTimestamp = intervalToString(
        moment
            .duration(
                moment(now).diff(
                    moment(gameServer.gameServerInfo.lastUpdateTimestamp)
                )
            )
            .asMilliseconds(),
        t
    )

    return (
        <Card fluid>
            <Image src={gameServer.gameServerInfo.headerImage} size="huge" />
            <CardContent>
                <CardHeader>
                    <Icon
                        name="power"
                        color={
                            gameServer.gameServerInfo.online ? 'green' : 'red'
                        }
                    />
                    {`${gameServer.address}:${gameServer.port}`}
                </CardHeader>
                {gameServer.gameServerInfo.online ? null : (
                    <CardMeta>
                        <span class="date">
                            {t('i18n.Last-time-was-online', {
                                when: lastUpdateTimestamp,
                            })}
                        </span>
                    </CardMeta>
                )}

                <CardDescription>
                    {descriptionLines.map((line, idx) => {
                        const result = [line]
                        if (idx < descriptionLines.length - 1) {
                            result.push(<br />)
                        }
                        return result
                    })}
                </CardDescription>
            </CardContent>
            <CardContent extra>
                <i class="user icon"></i>
                {`${gameServer.gameServerInfo.playersCount}/${gameServer.gameServerInfo.maxPlayersCount}`}
            </CardContent>
        </Card>
    )
}
