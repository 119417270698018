import { Button, Form, Modal } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { FormFieldWithError } from '../forms'
import { useApiRequest } from '../hoc'

export default function NoticeEditDialog({
    notice = { message: '', enabled: true },
    onClose,
}) {
    const { t } = useTranslation()
    const [state, setState] = useState(notice)
    const disabled = false

    const getValue = (name) => {
        return state[name]
    }
    const setValue = (name, value) => {
        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }))
    }

    const saveRequest = useApiRequest(
        ({ saveGameServerNotice }) => saveGameServerNotice,
        { onSuccess: onClose }
    )

    return (
        <Modal open={true} size="small">
            <Modal.Header>
                {t(notice.uuid ? 'i18n.Notice-editing' : 'i18n.Notice-adding')}
            </Modal.Header>
            <Modal.Content>
                <Form>
                    <FormFieldWithError
                        name="enabled"
                        type="checkbox"
                        label={t('i18n.Is-enabled')}
                        getValue={getValue}
                        setValue={setValue}
                        error={saveRequest.errorResponse}
                        disabled={disabled}
                    />
                    <FormFieldWithError
                        name="message"
                        type="text"
                        label={t('i18n.Interval-between-notices')}
                        getValue={getValue}
                        setValue={setValue}
                        error={saveRequest.errorResponse}
                        disabled={disabled}
                    />
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onClose} disabled={disabled}>
                    {t('i18n.Cancel')}
                </Button>
                <Button
                    onClick={() => {
                        saveRequest.fetch(state)
                    }}
                    positive={true}
                    disabled={disabled}
                >
                    {t(notice.uuid ? 'i18n.Save' : 'i18n.Add')}
                </Button>
            </Modal.Actions>
        </Modal>
    )
}
