import { TableCell, TableRow, Button } from 'semantic-ui-react'
import { useEffect, useState } from 'react'
import { useApiService } from '../../../hoc'
import { useDispatch } from 'react-redux'
import { addError } from '../../../../store/general-slice'
import useApiRequest from '../../../hoc/use-api-request'
import PermissionButton from './permission-button'

export default function MemberListItem({ member }) {
    const { excludeGameServerMember } = useApiService()
    const [state, setState] = useState({
        deleted: false,
        inProgress: false,
        error: null,
        permissions: {},
    })

    const permissionsRequest = useApiRequest(
        ({ getPermissionTypes }) => getPermissionTypes,
        {
            payload: [],
        }
    )
    useEffect(permissionsRequest.fetch, [])

    const dispatch = useDispatch()

    const doExcludeGameServerMember = () => {
        setState((prevState) => ({
            ...prevState,
            inProgress: true,
            error: false,
        }))
        excludeGameServerMember(member.uuid)
            .then((prevState) => setState({ ...prevState, deleted: true }))
            .catch((error) => {
                dispatch(addError(error))
            })
            .finally(() => {
                setState((prevState) => ({
                    ...prevState,
                    inProgress: false,
                }))
            })
    }

    return (
        <TableRow disabled={state.deleted}>
            <TableCell collapsing>{member.user.nickname}</TableCell>
            <TableCell>
                {permissionsRequest.payload.map((permission) => (
                    <PermissionButton
                        key={permission.name}
                        member={member}
                        permission={permission}
                        memberPermissions={member.gameServerMemberPermissions}
                        disabled={member.owner}
                    />
                ))}
            </TableCell>
            <TableCell collapsing>
                <Button
                    circular
                    icon="delete"
                    negative
                    disabled={member.owner || state.inProgress || state.deleted}
                    onClick={() => doExcludeGameServerMember()}
                />
            </TableCell>
        </TableRow>
    )
}
