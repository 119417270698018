import { useEffect, useState } from 'react'

export default function useNow(interval = 60000) {
    const [now, setNow] = useState(new Date())

    useEffect(() => {
        const timer = setInterval(() => {
            setNow(new Date())
        }, interval)
        return () => {
            clearInterval(timer)
        }
    }, [interval])

    return now
}
