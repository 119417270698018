import { useDialog } from '../../../hoc'
import { NoticeEditDialog } from '../../../dialogs'
import { DialogKeys } from '../../../../constants'
import NoticeSettings from './notice-settings'
import NoticeList from './notice-list'

export default function Notices({ gameServer }) {
    const noticeEditDialog = useDialog(
        DialogKeys.gameServerNoticeEdit,
        NoticeEditDialog
    )
    return (
        <>
            <NoticeSettings gameServer={gameServer} />
            <NoticeList gameServer={gameServer} />
            {noticeEditDialog.component}
        </>
    )
}
