import { useEffect, useState } from 'react'
import { withApiService } from '../hoc'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { setError, setTitle } from '../../store/general-slice'
import { Link } from 'react-router-dom'
import { useTitle } from '../hoc'
import {
    Container,
    Icon,
    Message,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
} from 'semantic-ui-react'
import { useSubscription } from 'react-stomp-hooks'
import { Websocket } from '../../constants'
import { useTranslation } from 'react-i18next'

export default function NotFoundPage() {
    const { t } = useTranslation()

    useTitle(t('i18n.Page-not-found'))

    return (
        <Container text>
            <Message negative size="large" icon>
                <Icon name="trash" />
                <Message.Content>
                    {t('i18n.Page-not-found-text')}
                </Message.Content>
            </Message>
        </Container>
    )
}
