import React, { useCallback, useEffect } from 'react'
import { Button, Form, Modal } from 'semantic-ui-react'
import { useSelector, useDispatch } from 'react-redux'
import { closeDialog } from '../../store/general-slice'
import { ApiServiceContext } from '../../contexts'
import { useState, useContext } from 'react'
import ErrorMessage from '../error-message'
import { useTranslation } from 'react-i18next'
import FormFieldWithError from '../forms/form-field-with-error'

const InitialState = {
    email: '',
    nickname: '',
    error: undefined,
    inProgress: false,
    successfull: false,
}

export default function UserRegistrationDialog() {
    const { t } = useTranslation()
    const [state, setState] = useState(InitialState)
    const { error, inProgress, successfull } = state

    const { createUser } = useContext(ApiServiceContext)

    const { open } = useSelector(({ general: { openedDialogs } }) => ({
        open: 'registration' in openedDialogs,
    }))

    const dispatch = useDispatch()
    const _closeDialog = () => {
        if (!inProgress) dispatch(closeDialog({ dialogKey: 'registration' }))
    }

    const doCreateUser = () => {
        setState({ ...state, inProgress: true, error: undefined })

        const { email, nickname } = state
        createUser({ email, nickname })
            .then(() => {
                setState({ ...state, successfull: true, inProgress: false })
            })
            .catch((error) => {
                setState({ ...state, error, inProgress: false })
            })
    }

    const onAcceptClick = () => {
        if (state.successfull) {
            _closeDialog()
        } else {
            doCreateUser()
        }
    }

    const getFieldValue = useCallback(
        (field) => {
            return state[field]
        },
        [state]
    )

    const setFieldValue = useCallback((field, value) => {
        return setState((prevState) => ({
            ...prevState,
            [field]: value,
            error: null,
        }))
    }, [])

    useEffect(() => {
        setState(InitialState)
    }, [open])

    const contentBlock = successfull ? (
        t('i18n.Create-user-success-message')
    ) : (
        <Form>
            <FormFieldWithError
                name="email"
                label={t('i18n.Email')}
                icon="at"
                disabled={inProgress}
                getValue={getFieldValue}
                setValue={setFieldValue}
                error={error}
            />
            <FormFieldWithError
                name="nickname"
                label={t('i18n.Nickname')}
                icon="at"
                disabled={inProgress}
                getValue={getFieldValue}
                setValue={setFieldValue}
                error={error}
            />
            {error ? <ErrorMessage error={error} /> : null}
        </Form>
    )

    const acceptButtonTitle = successfull ? t('i18n.Close') : t('i18n.Register')

    return (
        <Modal open={open} onClose={_closeDialog} size="mini">
            <Modal.Header>{t('i18n.Register-new-user')}</Modal.Header>
            <Modal.Content>{contentBlock}</Modal.Content>
            <Modal.Actions>
                {successfull ? null : (
                    <Button onClick={_closeDialog} disabled={inProgress}>
                        {t('i18n.Cancel')}
                    </Button>
                )}
                <Button
                    onClick={onAcceptClick}
                    disabled={inProgress}
                    positive={!successfull}
                >
                    {acceptButtonTitle}
                </Button>
            </Modal.Actions>
        </Modal>
    )
}
