import { FormCheckbox, FormInput } from 'semantic-ui-react'
import PropTypes from 'prop-types'

export default function FormField({
    name,
    type,
    getValue,
    setValue,
    ...formInputProps
}) {
    let input
    const props = {
        name,
        type,
        value: getValue(name),
        ...formInputProps,
    }
    switch (type) {
        case 'checkbox': {
            input = (
                <FormCheckbox
                    {...props}
                    onChange={(event, value) => setValue(name, value.checked)}
                    checked={props.value}
                />
            )
            break
        }
        default: {
            input = (
                <FormInput
                    {...props}
                    onChange={(event) => setValue(name, event.target.value)}
                />
            )
            break
        }
    }

    return input
}
FormField.propTypes = {
    name: PropTypes.string.isRequired,
    getValue: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
}
