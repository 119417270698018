import { Dropdown } from 'semantic-ui-react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { openDialog } from '../../store/general-slice'
import PropTypes from 'prop-types'

const DialogMenuItem = ({
    title,
    dialogKey,
    dialogProps,
    openDialog,
    ...otherProps
}) => {
    return (
        <Dropdown.Item
            onClick={() => {
                openDialog({ dialogKey, ...dialogProps })
            }}
            {...otherProps}
        >
            {title}
        </Dropdown.Item>
    )
}

DialogMenuItem.propTypes = {
    title: PropTypes.string,
    dialogKey: PropTypes.string,
    dialogProps: PropTypes.object,
}

const mapDispatchToProps = { openDialog: openDialog }

export default compose(connect(undefined, mapDispatchToProps))(DialogMenuItem)
