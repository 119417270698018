import { Button } from 'semantic-ui-react'
import useApiRequest from '../../../hoc/use-api-request'

export default function PermissionButton({
    member,
    permission,
    memberPermissions,
    disabled,
}) {
    const createPermission = useApiRequest(
        ({ createGameServerMemberPermission }) =>
            createGameServerMemberPermission
    )

    const deletePermission = useApiRequest(
        ({ deleteGameServerMemberPermission }) =>
            deleteGameServerMemberPermission
    )

    const enabled =
        memberPermissions.find(
            ({ permissionType: { name } }) => name === permission.name
        ) !== undefined
    const onToggled = () => {
        if (enabled) {
            deletePermission.fetch(member.uuid, permission.name)
        } else {
            createPermission.fetch(member.uuid, permission.name)
        }
    }

    return (
        <Button
            disabled={
                disabled ||
                createPermission.inProgress ||
                deletePermission.inProgress
            }
            circular
            onClick={onToggled}
            positive={enabled}
            size="tiny"
            compact
        >
            {permission.name}
        </Button>
    )
}
