const DialogKeys = {
    login: 'login',
    registration: 'registration',
    gameServerEdit: 'gameServerEdit',
    gameServerDelete: 'gameServerDelete',
    gameServerNoticeEdit: 'gameServerNoticeEdit',
}

const Websocket = {
    BrokerName: 'topic',
    Topics: {},
}
Websocket.Topics.Servers = `/${Websocket.BrokerName}/servers`
Websocket.Topics.Users = `/${Websocket.BrokerName}/users`

export { DialogKeys, Websocket }
