function hasPermissionOrOwner(member, permission) {
    if (!member) return false
    if (isOwner(member)) {
        return true
    }
    if (
        permission &&
        member.gameServerMemberPermissions.filter(
            ({ permissionType: { name } }) => name === permission
        ).length > 0
    ) {
        return true
    }
    return false
}

function isOwner(member) {
    return member ? member.owner : false
}

function getMember(user, members) {
    const filtered = members.filter(
        (member) => user && member.user.uuid === user.uuid && member.confirmed
    )
    if (filtered.length === 0) {
        return null
    } else if (filtered.length === 1) {
        return filtered[0]
    } else {
        throw Error(`More one member was founded by user ${user.uuid}.`)
    }
}

export { hasPermissionOrOwner, isOwner, getMember }
