import QuestionDialog from './question-dialog'
import PropTypes from 'prop-types'
import { withDialog } from '../hoc'
import { DialogKeys } from '../../constants'
import { useState, useContext } from 'react'
import { ApiServiceContext } from '../../contexts'
import { useTranslation } from 'react-i18next'

function GameServerDeleteDialog({
    open,
    onClose,
    gameServer: { uuid, address, port },
    onSuccess,
}) {
    const { t } = useTranslation()
    const [inProgress, setInProgress] = useState(false)
    const [done, setDone] = useState(false)
    const [error, setError] = useState()
    const { deleteGameServer } = useContext(ApiServiceContext)

    const content = error
        ? `${error.statusText} (${JSON.stringify(error)})`
        : done
        ? t('i18n.The-server-was-deleted', { address, port })
        : t('i18n.Server-delete-confirmation', { address, port })

    const answers = []
    if (!done) {
        answers.push({
            key: 'accept',
            text: t('i18n.Yes'),
            onClick: () => {
                setInProgress(true)
                deleteGameServer(uuid)
                    .then(() => {
                        setDone(true)
                        onSuccess()
                    })
                    .catch(setError)
                    .finally(() => setInProgress(false))
            },
            negative: true,
            disabled: inProgress,
        })
    }
    answers.push({
        key: 'reject',
        text: done ? t('i18n.Close') : t('i18n.No'),
        callOnClose: true,
        disabled: inProgress,
    })

    return (
        <QuestionDialog
            open={open}
            onClose={inProgress ? undefined : onClose}
            title={t('i18n.Server-deleting')}
            content={content}
            answers={answers}
        />
    )
}

GameServerDeleteDialog.propTypes = {
    onSuccess: PropTypes.func,
}

export default withDialog(DialogKeys.gameServerDelete)(GameServerDeleteDialog)
