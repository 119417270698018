import { Icon, Button, Input } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { useApiService } from '../../../hoc'
import ErrorMessage from '../../../error-message'

export default function SendInvite({ gameServer }) {
    const { t } = useTranslation()
    const [state, setState] = useState({
        nickname: '',
        inProgress: false,
        error: null,
    })
    const { sendGameServerMemberInvite } = useApiService()
    const doSubmit = () => {
        setState({ ...state, inProgress: true, error: null })
        sendGameServerMemberInvite({
            gameServerUuid: gameServer.uuid,
            nickname: state.nickname,
        })
            .then(() => setState({ ...state, inProgress: false, error: null }))
            .catch((error) => setState({ ...state, inProgress: false, error }))
    }

    return (
        <>
            <Input icon iconPosition="left">
                <Icon name="user" />
                <input
                    type="text"
                    placeholder={t('i18n.Nickname')}
                    value={state.nickname}
                    onChange={(event) =>
                        setState({
                            ...state,
                            error: null,
                            nickname: event.target.value,
                        })
                    }
                />
            </Input>
            <Button
                type="submit"
                positive
                onClick={doSubmit}
                disabled={state.inProgress || state.nickname.length === 0}
            >
                {t('i18n.Send-invite')}
            </Button>
            {state.error ? <ErrorMessage error={state.error} /> : null}
        </>
    )
}
