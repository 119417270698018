import { Table, TableBody, TableCell, TableRow } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useApiRequest, useApiService } from '../../../hoc'
import ErrorMessage from '../../../error-message'
import Spinner from '../../../spinner'
import { useSubscription } from 'react-stomp-hooks'
import { Websocket } from '../../../../constants'
import MemberListItem from './member-list-item'

export default function MemersList({ gameServer }) {
    const apiMembers = useApiRequest(
        ({ getGameServerMembersByGameServer }) =>
            getGameServerMembersByGameServer,
        { payload: [] }
    )

    const { t } = useTranslation()

    const fetchGameServerMembers = () => {
        apiMembers.fetch(gameServer.uuid)
    }
    useEffect(fetchGameServerMembers, [gameServer.uuid])
    useSubscription(
        Websocket.Topics.Servers + '/' + gameServer.uuid + '/members',
        fetchGameServerMembers
    )

    return apiMembers.error ? (
        <ErrorMessage error={apiMembers.error} />
    ) : (
        <Table basic="very" celled>
            <TableBody>
                {apiMembers.inProgress ? (
                    <TableRow>
                        <TableCell colSpan={3} textAlign="center">
                            <Spinner />
                        </TableCell>
                    </TableRow>
                ) : apiMembers.payload && apiMembers.payload.length > 0 ? (
                    apiMembers.payload.map((member) => (
                        <MemberListItem key={member.uuid} member={member} />
                    ))
                ) : (
                    <TableRow>
                        <TableCell colSpan={3} textAlign="center">
                            {t('i18n.There-are-no-members-yet')}
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    )
}
