import React, { useEffect, useState } from 'react'
import { Button, Form, Message, Modal } from 'semantic-ui-react'
import { FormFieldWithError } from '../forms'
import { useDispatch, useSelector } from 'react-redux'
import { setUser, closeDialog } from '../../store/general-slice'
import { Link } from 'react-router-dom'
import { ErrorMessage } from '../'
import { ApiServiceContext } from '../../contexts'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useApiRequest } from '../hoc'

const Mode = {
    Login: 'Login',
    RequestChangePassword: 'RequestChangePassword',
}

const InitialState = {
    payload: { email: '', password: '' },
    mode: Mode.Login,
    error: null,
}

export default function UserLoginDialog({ onClose }) {
    const { t } = useTranslation()
    const [state, setState] = useState(InitialState)

    const dispatch = useDispatch()

    const { user } = useSelector(({ general: { user } }) => ({
        user,
    }))

    const getFieldValue = (field) => {
        return state.payload[field]
    }

    const setFieldValue = (field, value) => {
        setState((prevState) => ({
            ...prevState,
            payload: { ...prevState.payload, [field]: value },
        }))
    }

    const apiUserLoginAndGetCurrentUser = useApiRequest(
        ({ userLoginAndGetCurrentUser }) => userLoginAndGetCurrentUser,
        {
            onSuccess: (response) => {
                dispatch(setUser(response.payload))
            },
            clearErrorOnProgress: true,
        }
    )

    const apiUserRequestChangePassword = useApiRequest(
        ({ userRequestChangePassword }) => userRequestChangePassword
    )

    let currentApi
    switch (state.mode) {
        case Mode.Login:
            currentApi = apiUserLoginAndGetCurrentUser
            break
        case Mode.RequestChangePassword:
            currentApi = apiUserRequestChangePassword
            break
        default:
            throw new Error('Uknown mode')
    }

    const onAcceptClick = () => {
        if (currentApi.ok) {
            onClose()
        } else {
            switch (state.mode) {
                case Mode.Login:
                    apiUserLoginAndGetCurrentUser.fetch(state.payload)
                    break
                case Mode.RequestChangePassword:
                    apiUserRequestChangePassword.fetch(state.payload.email)
                    break
                default:
                    throw new Error('Uknown mode')
            }
        }
    }

    const formTitle = () => {
        switch (state.mode) {
            case Mode.Login:
                return t('i18n.Login')
            case Mode.RequestChangePassword:
                return t('i18n.Reset-password')
            default:
                throw new Error('Uknown mode')
        }
    }

    const acceptButtonTitle = () => {
        if (currentApi.ok) return t('i18n.Close')
        switch (state.mode) {
            case Mode.Login:
                return t('i18n.Login')
            case Mode.RequestChangePassword:
                return t('i18n.Reset')
            default:
                throw new Error('Uknown mode')
        }
    }

    const modeSwitchLabel = () => {
        switch (state.mode) {
            case Mode.Login:
                return t('i18n.I-dont-remember-the-password')
            case Mode.RequestChangePassword:
                return t('i18n.I-remember-the-password')
            default:
                throw new Error('Uknown mode')
        }
    }

    const switchMode = () => {
        setState((prevState) => ({
            ...prevState,
            mode:
                state.mode === Mode.Login
                    ? Mode.RequestChangePassword
                    : Mode.Login,
        }))
    }

    const successfullContent = () => {
        switch (state.mode) {
            case Mode.Login:
                return t('i18n.Login-success-message', {
                    nickname: user ? user.nickname : '',
                })
            case Mode.RequestChangePassword:
                return t('i18n.Reset-password-success-message')
            default:
                throw new Error('Uknown mode')
        }
    }

    return (
        <Modal open={true} size="mini">
            <Modal.Header>{formTitle()}</Modal.Header>
            <Modal.Content>
                {currentApi.ok ? (
                    <Message success content={successfullContent()} />
                ) : (
                    <>
                        <Form>
                            <FormFieldWithError
                                name="email"
                                type="email"
                                getValue={getFieldValue}
                                setValue={setFieldValue}
                                label={t('i18n.Email')}
                                icon="at"
                                disabled={currentApi.inProgress}
                                error={currentApi.error}
                                iconPosition="left"
                            />
                            {state.mode === Mode.Login ? (
                                <FormFieldWithError
                                    name="password"
                                    type="password"
                                    getValue={getFieldValue}
                                    setValue={setFieldValue}
                                    label={t('i18n.Password')}
                                    icon="shield alternate"
                                    disabled={currentApi.inProgress}
                                    error={currentApi.error}
                                    iconPosition="left"
                                />
                            ) : null}
                            <Form.Field>
                                <Link onClick={switchMode}>
                                    {modeSwitchLabel()}
                                </Link>
                            </Form.Field>
                        </Form>
                        {currentApi.error ? (
                            <ErrorMessage error={currentApi.error} />
                        ) : null}
                    </>
                )}
            </Modal.Content>
            <Modal.Actions>
                {currentApi.ok ? null : (
                    <Button onClick={onClose} disabled={currentApi.inProgress}>
                        {t('i18n.Cancel')}
                    </Button>
                )}
                <Button
                    onClick={onAcceptClick}
                    type="submit"
                    positive={!currentApi.ok}
                    disabled={currentApi.inProgress}
                >
                    {acceptButtonTitle()}
                </Button>
            </Modal.Actions>
        </Modal>
    )
}
