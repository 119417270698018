import { useContext, useEffect, useState } from 'react'
import { ApiServiceContext } from '../../../contexts'
import { Link } from 'react-router-dom'
import {
    Card,
    CardContent,
    CardHeader,
    CardDescription,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
    Radio,
    PlaceholderLine,
    Placeholder,
    PlaceholderHeader,
    Menu,
    MenuItem,
} from 'semantic-ui-react'
import { intervalToString } from '../../../utils/date-time-utils'
import { useSubscription } from 'react-stomp-hooks'
import { Websocket } from '../../../constants'
import SemanticDatepicker from 'react-semantic-ui-datepickers'
import { useTranslation } from 'react-i18next'
import { useApiRequest } from '../../hoc'

export default function PlayerSessionDuration({ gameServerUuid }) {
    const { t } = useTranslation()
    const [periodType, setPeriodType] = useState(0)
    const [date, setDate] = useState(new Date())

    const apiPlayerSessionsDuration = useApiRequest(
        ({ getPlayerSessionsDuration }) => getPlayerSessionsDuration
    )

    const updatePlayerSessionsDuration = () => {
        const parameters = { gameServerUuid, periodType }
        if (periodType === 2) {
            parameters['startDateTime'] = date.toISOString()
        }
        apiPlayerSessionsDuration.fetch(parameters)
    }

    useEffect(updatePlayerSessionsDuration, [gameServerUuid, periodType, date])
    useSubscription(
        Websocket.Topics.Servers + '/' + gameServerUuid,
        updatePlayerSessionsDuration
    )

    const placeHolder = (
        <Placeholder>
            <PlaceholderHeader>
                <PlaceholderLine length="full" />
            </PlaceholderHeader>
        </Placeholder>
    )

    const renderRow = (playerSession, idx) => {
        return (
            <TableRow>
                <TableCell collapsing textAlign="left">
                    {idx !== undefined ? idx + 1 : null}
                </TableCell>
                <TableCell textAlign="center">
                    {playerSession ? (
                        <Link
                            target="_blank"
                            to={`https://steamcommunity.com/profiles/${playerSession.steamID}/`}
                        >
                            {playerSession.displayName}
                        </Link>
                    ) : (
                        placeHolder
                    )}
                </TableCell>
                <TableCell collapsing textAlign="right">
                    {playerSession
                        ? intervalToString(playerSession.duration * 1000, t)
                        : placeHolder}
                </TableCell>
            </TableRow>
        )
    }

    const PeriodItem = ({ value, title }) => {
        return (
            <MenuItem
                active={value === periodType}
                onClick={() => setPeriodType(value)}
            >
                {title}
            </MenuItem>
        )
    }

    return (
        <Card fluid>
            <CardContent>
                <CardHeader>
                    {t('i18n.Total-time-spent-on-the-server')}
                </CardHeader>
                <CardDescription textAlign="center">
                    <Menu stackable>
                        <PeriodItem
                            value={1}
                            title={t('i18n.Since-the-last-wipe')}
                        />
                        <PeriodItem
                            value={0}
                            title={t('i18n.For-the-entire-period')}
                        />
                        <PeriodItem
                            value={2}
                            title={t('i18n.From-a-certain-date')}
                        />
                    </Menu>
                    {periodType === 2 ? (
                        <SemanticDatepicker
                            clearable={false}
                            type="basic"
                            value={date}
                            clearOnSameDateClick={false}
                            onChange={(event, { value: newDate }) =>
                                setDate(newDate)
                            }
                        />
                    ) : null}
                </CardDescription>
                <Table
                    striped
                    size="small"
                    compact
                    singleLine
                    basic="very"
                    celled
                >
                    <TableHeader>
                        <TableHeaderCell collapsing textAlign="left">
                            #
                        </TableHeaderCell>
                        <TableHeaderCell textAlign="center">
                            {t('i18n.Player')}
                        </TableHeaderCell>
                        <TableHeaderCell collapsing textAlign="right">
                            {t('i18n.Duration')}
                        </TableHeaderCell>
                    </TableHeader>
                    <TableBody>
                        {apiPlayerSessionsDuration.ok ? (
                            apiPlayerSessionsDuration.payload.length > 0 ? (
                                apiPlayerSessionsDuration.payload.map(renderRow)
                            ) : (
                                <TableRow>
                                    <TableCell textAlign="center" colSpan={3}>
                                        {t('i18n.There-are-no-statistics-yet')}
                                    </TableCell>
                                </TableRow>
                            )
                        ) : (
                            renderRow()
                        )}
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    )
}
