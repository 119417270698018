function hasErrorWithoutFieldMessages(error) {
    if (
        error &&
        error.response &&
        error.response.fieldMessages &&
        error.response.fieldMessages.length === 0
    ) {
        return true
    } else {
        return false
    }
}

function hasErrorWithFieldMessage(error, name) {
    return (
        error &&
        error.payload &&
        error.payload.fieldMessages &&
        error.payload.fieldMessages[name]
    )
}

export { hasErrorWithoutFieldMessages, hasErrorWithFieldMessage }
