import { useEffect } from 'react'
import { useApiRequest, useDialog, useTitle } from '../hoc'
import {
    Container,
    Button,
    Dropdown,
    Table,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
} from 'semantic-ui-react'
import React from 'react'
import { useSelector } from 'react-redux'
import { GameServerEditDialog, GameServerDeleteDialog } from '../dialogs'
import { DialogKeys } from '../../constants'
import { useTranslation } from 'react-i18next'

export default function OwnServersPage() {
    const { t } = useTranslation()

    const apiGameServers = useApiRequest(
        ({ getGameServersOwned }) => getGameServersOwned,
        { inProgress: true, payload: [] }
    )

    const { user } = useSelector(({ general: { user } }) => ({ user }))

    const updateGameServers = () => {
        if (user) {
            apiGameServers.fetch()
        }
    }

    useTitle(t('i18n.Own-servers'))
    useEffect(updateGameServers, [user])

    const placeholder = '-'
    const notUnderined = (value1, value2) => {
        return value1 ? value1 : value2
    }

    const gameServerEditDialog = useDialog(
        DialogKeys.gameServerEdit,
        GameServerEditDialog,
        { props: { onSuccess: updateGameServers } }
    )
    const gameServerDeleteDialog = useDialog(
        DialogKeys.gameServerDelete,
        GameServerDeleteDialog,
        { props: { onSuccess: updateGameServers } }
    )

    return (
        <Container text>
            {user === null ? (
                <p>{t('i18n.own-servers.Unauth-message')}</p>
            ) : (
                <>
                    <Button onClick={() => gameServerEditDialog.open()}>
                        {t('i18n.Add')}
                    </Button>
                    {gameServerEditDialog.component}
                    {gameServerDeleteDialog.component}

                    <Table celled striped>
                        <TableHeader>
                            <TableHeaderCell collapsing textAlign="center">
                                {t('i18n.Address')}
                            </TableHeaderCell>
                            <TableHeaderCell>
                                {t('i18n.Description')}
                            </TableHeaderCell>
                            <TableHeaderCell collapsing textAlign="center">
                                {t('i18n.Players-count')}
                            </TableHeaderCell>
                        </TableHeader>
                        <tbody>
                            {apiGameServers.inProgress ||
                            apiGameServers.payload.length === 0 ? (
                                <TableRow>
                                    <TableCell textAlign="center" colSpan="3">
                                        {apiGameServers.inProgress ? (
                                            <div className="ui active centered inline loader"></div>
                                        ) : apiGameServers.payload.length ===
                                          0 ? (
                                            <>{t('i18n.No-servers-found')}</>
                                        ) : null}
                                    </TableCell>
                                </TableRow>
                            ) : (
                                apiGameServers.payload.map(
                                    ({
                                        uuid,
                                        address,
                                        port,
                                        password,
                                        gameServerInfo,
                                    }) => (
                                        <TableRow key={uuid}>
                                            <TableCell
                                                collapsing
                                                textAlign="center"
                                            >
                                                <Dropdown
                                                    text={`${address}:${port}`}
                                                >
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item
                                                            disabled={false}
                                                            onClick={() => {
                                                                gameServerEditDialog.open(
                                                                    {
                                                                        gameServer:
                                                                            {
                                                                                uuid,
                                                                                address,
                                                                                port,
                                                                                password,
                                                                            },
                                                                    }
                                                                )
                                                            }}
                                                        >
                                                            {t('i18n.Edit')}
                                                        </Dropdown.Item>
                                                        <Dropdown.Divider />
                                                        <Dropdown.Item
                                                            disabled={false}
                                                            onClick={() => {
                                                                gameServerDeleteDialog.open(
                                                                    {
                                                                        gameServer:
                                                                            {
                                                                                uuid,
                                                                                address,
                                                                                port,
                                                                            },
                                                                    }
                                                                )
                                                            }}
                                                        >
                                                            {t('i18n.Delete')}
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </TableCell>
                                            <TableCell
                                                disabled={
                                                    gameServerInfo === null
                                                }
                                                colSpan={
                                                    gameServerInfo === null
                                                        ? 2
                                                        : 0
                                                }
                                                textAlign={
                                                    gameServerInfo === null
                                                        ? 'center'
                                                        : 'left'
                                                }
                                            >
                                                {gameServerInfo === null
                                                    ? t(
                                                          'i18n.The-Server-was-not-connected'
                                                      )
                                                    : notUnderined(
                                                          gameServerInfo.hostname,
                                                          placeholder
                                                      )}
                                            </TableCell>
                                            {gameServerInfo !== null ? (
                                                <TableCell
                                                    collapsing
                                                    textAlign="center"
                                                >
                                                    {notUnderined(
                                                        gameServerInfo.playersCount,
                                                        placeholder
                                                    )}{' '}
                                                    /
                                                    {notUnderined(
                                                        gameServerInfo.maxPlayersCount,
                                                        placeholder
                                                    )}
                                                </TableCell>
                                            ) : null}
                                        </TableRow>
                                    )
                                )
                            )}
                        </tbody>
                    </Table>
                </>
            )}
        </Container>
    )
}
