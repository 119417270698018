import { TableCell, TableRow } from 'semantic-ui-react'
import FormFieldWithError from './form-field-with-error'

export default function FormFieldTableRow({ label, ...formFieldProps }) {
    return (
        <TableRow>
            <TableCell>{label}</TableCell>
            <TableCell>
                <FormFieldWithError {...formFieldProps} />
            </TableCell>
        </TableRow>
    )
}
