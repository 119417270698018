import useApiService from './use-api-service'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { addError } from '../../store/general-slice'

export default function useApiRequest(
    funcExtractor,
    {
        onSuccess,
        onError,

        applySuccess = true,
        clearSuccessOnError = false,
        clearSuccessOnProgress = false,

        applyError = false,
        clearErrorOnSuccess = true,
        clearErrorOnProgress = true,

        inProgress = false,

        ok = null,
        status = null,
        payload = null,

        success = null,
        error = null,
    } = {}
) {
    const apiService = useApiService()
    const dispatch = useDispatch()
    const [state, setState] = useState({
        inProgress,
        ok,
        status,
        payload,
        success,
        error,
    })
    const func = funcExtractor(apiService)

    const processResponse = ({ ok, ...response }) => {
        setState(({ success, error, ...prevState }) => {
            let newState = {
                ...prevState,
                ok,
                inProgress: false,
                success: ok ? response : clearSuccessOnError ? null : success,
                error: !ok ? response : clearErrorOnSuccess ? null : error,
            }
            if ((ok && applySuccess) || (!ok && applyError)) {
                newState = {
                    ...newState,
                    ...response,
                }
            }
            return newState
        })
        if (ok && onSuccess) {
            onSuccess(response)
        }
        if (ok && onError) {
            onError(response)
        }
    }

    return {
        ...state,
        fetch: (...params) => {
            setState((prevState) => {
                const result = { ...prevState, inProgress: true }
                if (clearSuccessOnProgress) {
                    result.success = success
                    if (ok) {
                        result.ok = ok
                        result.status = status
                        result.payload = payload
                    }
                }
                if (clearErrorOnProgress) {
                    result.error = error
                    if (!ok) {
                        result.ok = ok
                        result.status = status
                        result.payload = payload
                    }
                }
                return result
            })
            func(...params)
                .then(processResponse)
                .catch(({ ok, ...errorResponse }) => {
                    processResponse({ ok, ...errorResponse })
                    dispatch(addError(errorResponse))
                })
        },
        // clearError: () => {
        //     setState((prevState) => ({
        //         ...prevState,
        //         ok: null,
        //         error: null,
        //     }))
        // },
        reset: () => {
            setState({
                inProgress,
                ok,
                status,
                payload,
                success,
                error,
            })
        },
        setPayload: (payload) => {
            setState((prevState) => ({ ...prevState, payload }))
        },
    }
}
