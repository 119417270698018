import React, { useEffect, useState } from 'react'
import { Button, Form, Modal, Message } from 'semantic-ui-react'
import { FormFieldWithError } from '../forms'
import { useApiRequest } from '../hoc'
import { useTranslation } from 'react-i18next'
import ErrorMessage from '../error-message'

const InitialState = {
    address: '',
    port: 28016,
    password: '',
}

export default function GameServerEditDialog({
    onClose,
    onSuccess,
    gameServer,
}) {
    const { t } = useTranslation()
    const [payload, setPayload] = useState(
        gameServer ? gameServer : InitialState
    )

    let apiSaveGameServer = useApiRequest(
        ({ saveGameServer }) => saveGameServer,
        { onSuccess }
    )

    // useEffect(() => {
    //     apiSaveGameServer.reset()
    //     setPayload(gameServer ? gameServer : InitialState)
    // }, [gameServer])

    const onAcceptClick = () => {
        if (apiSaveGameServer.ok) {
            onClose()
        } else {
            apiSaveGameServer.fetch(payload)
        }
    }

    const getFieldValue = (field) => {
        return payload[field]
    }

    const setFieldValue = (field, value) => {
        setPayload({ ...payload, [field]: value })
    }

    const contentBlock = apiSaveGameServer.ok ? (
        <Message
            success
            content={t(
                payload.uuid
                    ? 'i18n.The-server-was-saved'
                    : 'i18n.The-server-was-created',
                { address: payload.address, port: payload.port }
            )}
        />
    ) : (
        <Form>
            <FormFieldWithError
                label={t('i18n.Address')}
                disabled={apiSaveGameServer.inProgress}
                getValue={getFieldValue}
                setValue={setFieldValue}
                error={apiSaveGameServer.error}
                name="address"
            />
            <FormFieldWithError
                label={t('i18n.Port')}
                disabled={apiSaveGameServer.inProgress}
                getValue={getFieldValue}
                setValue={setFieldValue}
                error={apiSaveGameServer.error}
                name="port"
                type="number"
            />
            <FormFieldWithError
                label={t('i18n.Password')}
                disabled={apiSaveGameServer.inProgress}
                getValue={getFieldValue}
                setValue={setFieldValue}
                error={apiSaveGameServer.error}
                name="password"
            />
            {apiSaveGameServer.error ? (
                <ErrorMessage error={apiSaveGameServer.error} />
            ) : null}
        </Form>
    )

    return (
        <Modal open={true} size="mini">
            <Modal.Header>
                {t(payload.uuid ? 'i18n.Server-editing' : 'i18n.Server-adding')}
            </Modal.Header>
            <Modal.Content>{contentBlock}</Modal.Content>
            <Modal.Actions>
                {apiSaveGameServer.ok ? null : (
                    <Button
                        disabled={apiSaveGameServer.inProgress}
                        onClick={onClose}
                    >
                        {t('i18n.Cancel')}
                    </Button>
                )}
                <Button
                    onClick={onAcceptClick}
                    disabled={
                        apiSaveGameServer.inProgress ||
                        payload.address.trim().length === 0 ||
                        payload.password.trim().length === 0
                    }
                    positive={!apiSaveGameServer.ok}
                >
                    {apiSaveGameServer.ok
                        ? t('i18n.Close')
                        : payload.uuid
                        ? t('i18n.Save')
                        : t('i18n.Add')}
                </Button>
            </Modal.Actions>
        </Modal>
    )
}
