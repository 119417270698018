import { useTranslation } from 'react-i18next'
import {
    Icon,
    List,
    ListContent,
    ListDescription,
    ListHeader,
    ListIcon,
    ListItem,
    Segment,
} from 'semantic-ui-react'

export default function News() {
    const { t } = useTranslation()

    const news = [
        {
            title: 'i18n.news.GameServerNotices.date',
            content: 'i18n.news.GameServerNotices.description',
        },
    ]

    return (
        <List>
            {news.map(({ title, content }) => (
                <ListItem>
                    <ListIcon>
                        <Icon name="newspaper" />
                    </ListIcon>
                    <ListContent>
                        <ListHeader>{t(title)}</ListHeader>
                        <ListDescription>{t(content)}</ListDescription>
                    </ListContent>
                </ListItem>
            ))}
        </List>
    )
}
