import { Link } from 'react-router-dom'
import PropTypes, { shape } from 'prop-types'
import { getGameServerTitle } from '../utils'

export default function GameServerLink({ gameServer }) {
    return (
        <Link to={`/servers/${gameServer.uuid}`}>
            {getGameServerTitle(gameServer)}
        </Link>
    )
}
GameServerLink.propTypes = {
    gameServer: shape({
        address: PropTypes.string.isRequired,
        port: PropTypes.number.isRequired,
        gameServerInfo: PropTypes.shape({
            hostname: PropTypes.string.isRequired,
        }),
    }),
}
