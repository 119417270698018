import {
    Link,
    Route,
    Routes,
    useParams,
    useResolvedPath,
} from 'react-router-dom'
import { useEffect, useState } from 'react'
import Spinner from '../../spinner'
import { useTitle, useApiRequest } from '../../hoc'
import { Grid, Menu, MenuItem } from 'semantic-ui-react'
import PlayerSessionDuration from './player-session-duration'
import { useSubscription } from 'react-stomp-hooks'
import { Websocket } from '../../../constants'
import GeneralInfo from './general-info'
import Chat from './chat'
import OnlinePlayers from './online-players'
import Members from './members'
import Notices from './notices'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
    getMember,
    hasPermissionOrOwner,
    isOwner,
} from '../../../utils/permissions'
import { getGameServerTitle } from '../../../utils'

export default function ServerPage() {
    const { t } = useTranslation()
    const { gameServerUuid } = useParams()
    const { pathname } = useResolvedPath()

    const apiGameServer = useApiRequest(({ getGameServer }) => getGameServer, {
        inProgress: true,
    })

    const apiMembers = useApiRequest(
        ({ getGameServerMembersByGameServer }) =>
            getGameServerMembersByGameServer,
        { inProgress: true, payload: [] }
    )

    const [, setTitle] = useTitle()

    const { title, user } = useSelector(({ general: { title, user } }) => ({
        title,
        user,
    }))

    if (apiGameServer.ok) {
        setTitle(getGameServerTitle(apiGameServer.payload))
    }

    const updateGameServer = () => {
        apiGameServer.fetch(gameServerUuid)
    }

    useEffect(updateGameServer, [gameServerUuid])
    useSubscription(
        Websocket.Topics.Servers + '/' + gameServerUuid,
        updateGameServer
    )

    useEffect(() => {
        apiMembers.fetch(gameServerUuid)
    }, [gameServerUuid, user])

    if (apiGameServer.inProgress || apiMembers.inProgress) {
        return <Spinner />
    }

    const TabMenuItem = ({ title, url }) => {
        return (
            <MenuItem active={pathname === url} as={Link} to={url}>
                {title}
            </MenuItem>
        )
    }

    const tabs = []
    const addTab = (title, path, content) => {
        tabs.push({ title, path, content })
    }
    addTab(
        t('i18n.Summary'),
        '',
        <GeneralInfo gameServer={apiGameServer.payload} />
    )
    addTab(
        t('i18n.Online'),
        '/online',
        <OnlinePlayers
            gameServer={apiGameServer.payload}
            inProgress={apiGameServer.inProgress}
        />
    )
    addTab(
        t('i18n.Statistics'),
        `/duration`,
        <PlayerSessionDuration gameServerUuid={gameServerUuid} />
    )
    addTab(t('i18n.Chat'), `/chat`, <Chat gameServer={apiGameServer.payload} />)

    const member = getMember(user, apiMembers.payload)
    if (isOwner(member)) {
        addTab(
            t('i18n.Membership'),
            `/members`,
            <Members gameServer={apiGameServer.payload} />
        )
    }

    if (hasPermissionOrOwner(member, 'NoticesControl')) {
        addTab(
            t('i18n.Notices'),
            `/notices`,
            <Notices gameServer={apiGameServer.payload} />
        )
    }

    const ServerMenu = ({ vertical = false }) => {
        return (
            <Menu tabular vertical={vertical} size="small">
                {tabs.map(({ title, path }) => (
                    <TabMenuItem
                        title={title}
                        url={`/servers/${gameServerUuid}${path}`}
                    />
                ))}
            </Menu>
        )
    }

    const Content = (
        <Routes>
            {tabs.map(({ path, content }) => (
                <Route path={path} element={content} />
            ))}
        </Routes>
    )

    return (
        <>
            <Grid container>
                <Grid.Row centered>
                    <ServerMenu />
                </Grid.Row>
                <Grid.Row centered>
                    <Grid.Column mobile={16} computer={10}>
                        {Content}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    )
}
