import { Dropdown } from 'semantic-ui-react'
import { useApiRequest } from '../hoc'
import { clearUser } from '../../store/general-slice'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

export default function LogoutDropdownItem() {
    const { t } = useTranslation()

    const dispatch = useDispatch()
    const apiLogout = useApiRequest(({ userLogout }) => userLogout, {
        onSuccess: () => {
            dispatch(clearUser())
        },
    })

    return (
        <Dropdown.Item
            disabled={apiLogout.inProgress}
            onClick={apiLogout.fetch}
        >
            {t('i18n.Logout')}
        </Dropdown.Item>
    )
}
