class ApiService {
    _baseUrl = '/api'

    getResponse = async (url, details, noneThrowStatuses = []) => {
        const resource = await fetch(`${this._baseUrl}${url}`, details)

        let payload = undefined
        try {
            payload = await resource.json()
        } catch (error) {
            // Noting to do
        }

        const response = {
            ok: resource.ok,
            status: resource.status,
            payload: payload ? payload : { messages: [resource.statusText] },
        }
        if (!resource.ok && noneThrowStatuses.indexOf(resource.status) === -1) {
            throw response
        }

        return response
    }

    createUser = async (payload) => {
        return await this.getResponse('/users/create', {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: { 'Content-Type': 'application/json' },
        })
    }

    userLogin = async ({ email, password }) => {
        const formData = new FormData()
        formData.append('email', email)
        formData.append('password', password)

        return await this.getResponse(
            '/login',
            {
                method: 'POST',
                credentials: 'include',
                body: new URLSearchParams(formData),
            },
            [403]
        )
    }

    userLoginAndGetCurrentUser = async (payload) => {
        return new Promise((resolve, reject) => {
            this.userLogin(payload)
                .then((response) => {
                    if (response.ok) {
                        this.getCurrentUser().then(resolve).catch(reject)
                    } else {
                        resolve(response)
                    }
                })
                .catch(reject)
        })
    }

    userConfirmEmail = async (userTokenUuid) => {
        return await this.getResponse(
            `/users/confirm_email?${new URLSearchParams({ userTokenUuid })}`,
            {
                method: 'POST',
            }
        )
    }

    userRequestChangePassword = async (email) => {
        return await this.getResponse(
            `/users/request_change_password`,
            {
                method: 'POST',
                body: JSON.stringify({ email }),
                headers: { 'Content-Type': 'application/json' },
            },
            [422]
        )
    }

    userChangePassword = async (password, userTokenUuid) => {
        const formData = new FormData()
        formData.append('password', password)

        const params = {}
        if (userTokenUuid) {
            params.userTokenUuid = userTokenUuid
        }

        return await this.getResponse(
            `/users/change_password?${new URLSearchParams(params)}`,
            {
                method: 'POST',
                body: JSON.stringify({ password }),
                headers: { 'Content-Type': 'application/json' },
            }
        )
    }

    userLogout = async () => {
        return await this.getResponse('/logout', {
            method: 'POST',
        })
    }

    getCurrentUser = async () => {
        return await this.getResponse('/users/current', {
            method: 'GET',
        })
    }

    getGameServersOwned = async () => {
        return await this.getResponse('/game-servers/owned', {
            method: 'GET',
        })
    }

    getGameServers = async () => {
        return await this.getResponse('/game-servers', {
            method: 'GET',
        })
    }

    getGameServer = async (uuid) => {
        return await this.getResponse(`/game-servers/${uuid}`, {
            method: 'GET',
        })
    }

    createGameServer = async (gameServer) => {
        return await this.getResponse(
            '/game-servers',
            {
                method: 'POST',
                body: JSON.stringify(gameServer),
                headers: { 'Content-Type': 'application/json' },
            },
            [422]
        )
    }

    updateGameServer = async ({ uuid, ...gameServer }) => {
        return await this.getResponse(
            `/game-servers/${uuid}`,
            {
                method: 'PUT',
                body: JSON.stringify(gameServer),
                headers: { 'Content-Type': 'application/json' },
            },
            [422]
        )
    }

    saveGameServer = async (gameServer) => {
        return gameServer.uuid
            ? this.updateGameServer(gameServer)
            : this.createGameServer(gameServer)
    }

    deleteGameServer = async (uuid) => {
        return await this.getResponse(`/game-servers/${uuid}`, {
            method: 'DELETE',
        })
    }

    getPlayerSessionsDuration = async (parameters) => {
        return await this.getResponse(
            `/player-sessions/duration?` + new URLSearchParams(parameters),
            {
                method: 'GET',
            }
        )
    }

    getChatMessages = async ({
        gameServerUuid,
        startDateTime,
        endDateTime,
    }) => {
        const parameters = { gameServerUuid }
        if (startDateTime) {
            parameters['startDateTime'] = startDateTime.toISOString()
        }
        if (endDateTime) {
            parameters['endDateTime'] = endDateTime.toISOString()
        }
        const response = await this.getResponse(
            `/chat?` + new URLSearchParams(parameters),
            {
                method: 'GET',
            }
        )
        return response
    }

    changeLocale = async (locale) => {
        return await this.getResponse(
            `/users/localization?` + new URLSearchParams({ locale }),
            {
                method: 'POST',
            }
        )
    }

    changeTimezone = async (timezone) => {
        return await this.getResponse(
            `/users/timezone?` + new URLSearchParams({ timezone }),
            {
                method: 'POST',
            }
        )
    }

    getGameServerMembersByGameServer = async (gameServerUuid) => {
        return await this.getResponse(
            `/game-server-members/by_game_server?` +
                new URLSearchParams({ gameServerUuid }),
            {
                method: 'GET',
            },
            [403]
        )
    }

    sendGameServerMemberInvite = async (payload) => {
        return await this.getResponse('/game-server-members/invite', {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: { 'Content-Type': 'application/json' },
        })
    }

    confirmGameServerMember = async (uuid) => {
        return await this.getResponse(`/game-server-members/${uuid}/confirm`, {
            method: 'POST',
        })
    }

    excludeGameServerMember = async (uuid) => {
        return await this.getResponse(`/game-server-members/${uuid}`, {
            method: 'DELETE',
        })
    }

    getPermissionTypes = async () => {
        return await this.getResponse(`/permission-types`, {
            method: 'GET',
        })
    }

    createGameServerMemberPermission = async (
        gameServerMemberUuid,
        permissionName
    ) => {
        return await this.getResponse(
            `/game-server-members/${gameServerMemberUuid}/permission/${permissionName}`,
            {
                method: 'POST',
            }
        )
    }

    deleteGameServerMemberPermission = async (
        gameServerMemberUuid,
        permissionName
    ) => {
        return await this.getResponse(
            `/game-server-members/${gameServerMemberUuid}/permission/${permissionName}`,
            {
                method: 'DELETE',
            }
        )
    }

    getGameServerMembersByCurrentUser = async () => {
        return await this.getResponse(`/game-server-members/by_current_user`, {
            method: 'GET',
        })
    }

    getGameServerNoticeSettings = async (gameServerUuid) => {
        return await this.getResponse(
            `/game-server-notice-settings?` +
                new URLSearchParams({ gameServerUuid }),
            {
                method: 'GET',
            }
        )
    }

    saveGameServerNoticeSettings = async (gameServerUuid, payload) => {
        return await this.getResponse(
            `/game-server-notice-settings?` +
                new URLSearchParams({ gameServerUuid }),
            {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: { 'Content-Type': 'application/json' },
            },
            [422]
        )
    }

    getGameServerNotices = async (gameServerUuid) => {
        return await this.getResponse(
            `/game-server-notices?` + new URLSearchParams({ gameServerUuid }),
            {
                method: 'GET',
            }
        )
    }

    createGameServerNotice = async ({ message, enabled, gameServer }) => {
        return await this.getResponse('/game-server-notices', {
            method: 'POST',
            body: JSON.stringify({ message, enabled, gameServer }),
            headers: { 'Content-Type': 'application/json' },
        })
    }

    updateGameServerNotice = async ({ uuid, message, enabled }) => {
        return await this.getResponse(`/game-server-notices/${uuid}`, {
            method: 'PUT',
            body: JSON.stringify({ message, enabled }),
            headers: { 'Content-Type': 'application/json' },
        })
    }

    saveGameServerNotice = async (gameServerNotice) => {
        return gameServerNotice.uuid
            ? this.updateGameServerNotice(gameServerNotice)
            : this.createGameServerNotice(gameServerNotice)
    }

    deleteGameServerNotice = async (gameServerNoticeUuid) => {
        return await this.getResponse(
            `/game-server-notices/${gameServerNoticeUuid}`,
            {
                method: 'DELETE',
            }
        )
    }
}

export default ApiService
