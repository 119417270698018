import {
    Table,
    TableBody,
    TableRow,
    TableCell,
    Form,
    Card,
    Button,
} from 'semantic-ui-react'
import Spinner from '../../../spinner'
import { useApiRequest } from '../../../hoc'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ErrorMessage from '../../../error-message'
import { hasErrorWithoutFieldMessages } from '../../../../utils'
import { FormFieldTableRow } from '../../../forms'

export default function NoticeSettings({ gameServer }) {
    const { t } = useTranslation()
    const [state, setState] = useState({ interval: '15', enabled: false })

    const getRequest = useApiRequest(
        ({ getGameServerNoticeSettings }) => getGameServerNoticeSettings,
        {
            inProgress: true,
            onSuccess: ({ payload }) => setState(payload),
        }
    )

    const saveRequest = useApiRequest(
        ({ saveGameServerNoticeSettings }) => saveGameServerNoticeSettings
    )

    useEffect(() => getRequest.fetch(gameServer.uuid), [gameServer.uuid])

    const getValue = (name) => {
        return state[name]
    }
    const setValue = (name, value) => {
        saveRequest.reset()
        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }))
    }
    return (
        <Card fluid>
            <Card.Content>
                <Card.Header>{t('i18n.Settings')}</Card.Header>
                <Form>
                    <Table basic="very" celled fixed>
                        <TableBody>
                            {getRequest.inProgress ? (
                                <TableRow>
                                    <TableCell colSpan={3} textAlign="center">
                                        <Spinner />
                                    </TableCell>
                                </TableRow>
                            ) : (
                                <>
                                    <FormFieldTableRow
                                        disabled={saveRequest.inProgress}
                                        label={t('i18n.Is-enabled')}
                                        type="checkbox"
                                        name="enabled"
                                        getValue={getValue}
                                        setValue={setValue}
                                        error={saveRequest.error}
                                    />
                                    <FormFieldTableRow
                                        disabled={saveRequest.inProgress}
                                        label={t(
                                            'i18n.Interval-between-notices'
                                        )}
                                        type="number"
                                        name="interval"
                                        getValue={getValue}
                                        setValue={setValue}
                                        error={saveRequest.error}
                                    />
                                </>
                            )}
                            {hasErrorWithoutFieldMessages(saveRequest.error) ? (
                                <TableRow>
                                    <TableCell colSpan={2} textAlign="center">
                                        <ErrorMessage
                                            error={saveRequest.error}
                                        />
                                    </TableCell>
                                </TableRow>
                            ) : null}
                        </TableBody>
                    </Table>
                </Form>
            </Card.Content>
            <Button
                positive
                disabled={saveRequest.inProgress}
                onClick={() => saveRequest.fetch(gameServer.uuid, state)}
            >
                {t('i18n.Save')}
            </Button>
        </Card>
    )
}
