import { useDispatch, useSelector } from 'react-redux'
import { closeDialog, openDialog } from '../../store/general-slice'

export default function useDialog(dialogKey, Dialog, { props } = {}) {
    const { payload } = useSelector(({ general: { openedDialogs } }) => ({
        payload: openedDialogs[dialogKey],
    }))
    const dispatch = useDispatch()

    return {
        open: (payload) => dispatch(openDialog({ dialogKey, ...payload })),
        component: payload ? (
            <Dialog
                {...props}
                {...payload}
                onClose={() =>
                    dispatch(
                        closeDialog({
                            dialogKey: dialogKey,
                        })
                    )
                }
            />
        ) : null,
    }
}
