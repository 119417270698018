import PropTypes from 'prop-types'
import React from 'react'
import { Button, Modal } from 'semantic-ui-react'

export default function QuestionDialog({
    title,
    content,
    open,
    onClose,
    answers,
}) {
    const onCloseFunc = () => {
        if (onClose) onClose()
        answers.forEach((answer) => {
            if (answer.callWhenClose) answer.onClick()
        })
    }

    return (
        <Modal open={open} onClose={onCloseFunc} size="mini">
            <Modal.Header>{title}</Modal.Header>
            <Modal.Content>{content}</Modal.Content>
            <Modal.Actions>
                {answers.map(
                    ({
                        key,
                        text,
                        onClick,
                        positive,
                        negative,
                        disabled,
                        callOnClose,
                    }) => (
                        <Button
                            onClick={() => {
                                if (onClick) onClick()
                                if (callOnClose) onClose()
                            }}
                            key={key}
                            positive={positive}
                            negative={negative}
                            disabled={disabled}
                        >
                            {text}
                        </Button>
                    )
                )}
            </Modal.Actions>
        </Modal>
    )
}

QuestionDialog.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.node.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    answers: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
            onClick: PropTypes.func,
            positive: PropTypes.bool,
            negative: PropTypes.bool,
            disabled: PropTypes.bool,
            callWhenClose: PropTypes.bool,
            callOnClose: PropTypes.bool,
        })
    ).isRequired,
}
