import { useApiRequest, useTitle } from '../hoc'
import { useTranslation } from 'react-i18next'
import {
    Table,
    TableRow,
    TableCell,
    TableBody,
    Button,
    Segment,
    Container,
    Card,
    Label,
} from 'semantic-ui-react'
import Spinner from '../spinner'
import { useEffect, useState } from 'react'
import { GameServerLink } from '../'
import { useSubscription } from 'react-stomp-hooks'
import { Websocket } from '../../constants'
import { useSelector } from 'react-redux'
import { redirect } from 'react-router-dom'

export function PermissionLabel({ permission, memberPermissions }) {
    const enabled =
        memberPermissions.find(
            ({ permissionType: { name } }) => name === permission.name
        ) !== undefined

    return (
        <Label circular color={enabled ? 'green' : 'grey'}>
            {permission.name}
        </Label>
    )
}

function MemberListItem({ member, permissions, confirmed }) {
    const [state, setState] = useState({ disabled: false })

    const confirmRequest = useApiRequest(
        ({ confirmGameServerMember }) => confirmGameServerMember,
        { onSuccess: () => setState({ disabled: true }) }
    )

    const excludeRequest = useApiRequest(
        ({ excludeGameServerMember }) => excludeGameServerMember,
        { onSuccess: () => setState({ disabled: true }) }
    )

    const disabled =
        state.disabled || confirmRequest.inProgress || excludeRequest.inProgress

    return (
        <TableRow disabled={state.deleted}>
            <TableCell collapsing>
                <GameServerLink gameServer={member.gameServer} />
            </TableCell>
            <TableCell>
                {permissions.map((permission) => (
                    <PermissionLabel
                        key={permission.name}
                        permission={permission}
                        memberPermissions={member.gameServerMemberPermissions}
                    />
                ))}
            </TableCell>
            <TableCell collapsing>
                {!confirmed ? (
                    <Button
                        circular
                        icon="check"
                        positive
                        disabled={disabled}
                        onClick={() => confirmRequest.fetch(member.uuid)}
                    />
                ) : null}
                <Button
                    circular
                    icon="delete"
                    negative
                    disabled={member.owner || disabled}
                    onClick={() => excludeRequest.fetch(member.uuid)}
                />
            </TableCell>
        </TableRow>
    )
}

function GameServersMembershipTable({ members, permissions, confirmed }) {
    const { t } = useTranslation()

    const filteredMembers = members.filter(
        (member) => member.confirmed === confirmed
    )

    return (
        <Table basic="very" celled>
            <TableBody>
                {filteredMembers.length > 0 ? (
                    filteredMembers.map((member) => (
                        <MemberListItem
                            key={member.uuid}
                            member={member}
                            permissions={permissions}
                            confirmed={confirmed}
                        />
                    ))
                ) : (
                    <TableRow>
                        <TableCell colSpan={3} textAlign="center">
                            {t(
                                confirmed
                                    ? 'i18n.There-is-no-confirmed-membership'
                                    : 'i18n.No-invitations'
                            )}
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    )
}

export default function GameServersMembership() {
    const { t } = useTranslation()
    useTitle(t('i18n.Game-servers-membership'))
    const { user } = useSelector(({ general: { user } }) => ({ user }))

    const permissionsRequest = useApiRequest(
        ({ getPermissionTypes }) => getPermissionTypes,
        {
            payload: [],
        }
    )

    const gameServerMembersRequest = useApiRequest(
        ({ getGameServerMembersByCurrentUser }) =>
            getGameServerMembersByCurrentUser,
        { inProgress: true, payload: [] }
    )

    useEffect(() => {
        permissionsRequest.fetch()
        gameServerMembersRequest.fetch()
    }, [])
    useSubscription(
        Websocket.Topics.Users + '/' + user.uuid + '/members',
        gameServerMembersRequest.fetch
    )

    const loading =
        gameServerMembersRequest.inProgress || permissionsRequest.inProgress
    return (
        <Container text>
            <Segment textAlign="center" loading={loading}>
                <h4>{t('i18n.Accepted-membership')}</h4>
                <GameServersMembershipTable
                    members={gameServerMembersRequest.payload}
                    permissions={permissionsRequest.payload}
                    confirmed={true}
                />
                <h4>{t('i18n.Invites')}</h4>
                <GameServersMembershipTable
                    members={gameServerMembersRequest.payload}
                    permissions={permissionsRequest.payload}
                    confirmed={false}
                />
            </Segment>
        </Container>
    )
}
