import { Label } from 'semantic-ui-react'
import { hasErrorWithFieldMessage } from '../../utils/errors'
import PropTypes from 'prop-types'
import FormField from './form-field'

export default function FormFieldWithError({ error, ...otherProps }) {
    const hasError = hasErrorWithFieldMessage(error, otherProps.name)
    return (
        <>
            <FormField {...otherProps} />
            {hasError ? (
                <Label
                    color="red"
                    basic
                    pointing
                    style={{ marginTop: 0, marginBottom: '1em' }}
                >
                    {error.payload.fieldMessages[otherProps.name]}
                </Label>
            ) : null}
        </>
    )
}
FormFieldWithError.propTypes = {
    name: PropTypes.string.isRequired,
    getValue: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    error: PropTypes.object,
}
