import {
    Table,
    TableBody,
    TableRow,
    TableCell,
    Card,
    Button,
    TableHeader,
    TableHeaderCell,
} from 'semantic-ui-react'
import Spinner from '../../../spinner'
import { useApiRequest } from '../../../hoc'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ErrorMessage from '../../../error-message'
import { useDispatch } from 'react-redux'
import { openDialog } from '../../../../store/general-slice'
import { DialogKeys } from '../../../../constants'
import { useSubscription } from 'react-stomp-hooks'
import { Websocket } from '../../../../constants'
import NoticeItem from './notice-item'

export default function NoticeList({ gameServer }) {
    const { t } = useTranslation()

    const noticesRequest = useApiRequest(
        ({ getGameServerNotices }) => getGameServerNotices,
        { inProgress: true, payload: [] }
    )

    useEffect(() => {
        noticesRequest.fetch(gameServer.uuid)
    }, [gameServer.uuid])

    useSubscription(
        Websocket.Topics.gameServer + '/' + gameServer.uuid + '/notices',
        () => noticesRequest.fetch(gameServer.uuid)
    )

    const dispatch = useDispatch()

    const colSpan = 6
    return (
        <Card fluid>
            <Card.Content>
                <Card.Header>{t('i18n.Notices')}</Card.Header>
                <Table basic="very" celled>
                    <TableHeader>
                        <TableHeaderCell>#</TableHeaderCell>
                        <TableHeaderCell>Текст объявления</TableHeaderCell>
                        <TableHeaderCell>
                            Последний редактировавший пользователь
                        </TableHeaderCell>
                        <TableHeaderCell>
                            Когда в последний раз отображалось
                        </TableHeaderCell>
                        <TableHeaderCell>Статус</TableHeaderCell>
                        <TableHeaderCell>Действия</TableHeaderCell>
                    </TableHeader>
                    <TableBody>
                        {noticesRequest.inProgress ? (
                            <TableRow>
                                <TableCell colSpan={colSpan} textAlign="center">
                                    <Spinner />
                                </TableCell>
                            </TableRow>
                        ) : !noticesRequest.ok ? (
                            <TableRow>
                                <TableCell colSpan={colSpan} textAlign="center">
                                    <ErrorMessage
                                        error={noticesRequest.error}
                                    />
                                </TableCell>
                            </TableRow>
                        ) : noticesRequest.payload.length > 0 ? (
                            noticesRequest.payload.map((notice) => (
                                <NoticeItem key={notice.uuid} notice={notice} />
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={colSpan} textAlign="center">
                                    {t('i18n.You-dont-have-notices-yet')}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Card.Content>
            <Button
                disabled={false}
                onClick={() =>
                    dispatch(
                        openDialog({
                            dialogKey: DialogKeys.gameServerNoticeEdit,
                            notice: { gameServer: { uuid: gameServer.uuid } },
                        })
                    )
                }
            >
                {t('i18n.Add')}
            </Button>
        </Card>
    )
}
