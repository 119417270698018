import { createSlice } from '@reduxjs/toolkit'

export const generalSlice = createSlice({
    name: 'general',
    initialState: {
        title: null,
        user: null,
        openedDialogs: {},
        errors: [],
        userPreferences: JSON.parse(
            localStorage.getItem('userPreferences')
        ) || { timezone: 'UTC' },
    },
    reducers: {
        setTitle: (state, action) => {
            state.title = action.payload
        },
        setUser: (state, action) => {
            state.user = action.payload
        },
        clearUser: (state) => {
            state.user = null
        },
        openDialog: (state, { payload: { dialogKey, ...dialogProps } }) => {
            state.openedDialogs[dialogKey] = dialogProps
        },
        closeDialog: (state, { payload: { dialogKey } }) => {
            delete state.openedDialogs[dialogKey]
        },
        addError: (state, action) => {
            state.errors.push(action.payload)
            state.openedDialogs['error'] = true
        },
        clearErrors: (state) => {
            state.errors = []
            delete state.openedDialogs['error']
        },
        setUserPreferenceTimezone: (state, action) => {
            state.userPreferences.timezone = action.payload
            localStorage.setItem(
                'userPreferences',
                JSON.stringify(state.userPreferences)
            )
        },
    },
})

export const {
    setTitle,
    setUser,
    clearUser,
    openDialog,
    closeDialog,
    addError,
    clearErrors,
} = generalSlice.actions
export default generalSlice.reducer
