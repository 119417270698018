import React, { useEffect, useState } from 'react'
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom'
import { Container, Dropdown, Header, Menu, Grid } from 'semantic-ui-react'
import {
    HomePage,
    OwnServersPage,
    ServerPage,
    UserPreferencesPage,
    UserPasswordChangePage,
    NotFoundPage,
} from '../pages'
import { ApiServiceContext } from '../../contexts'
import { ApiService } from '../../service'
import { LogoutDropdownItem } from '../menu-items'
import {
    UserRegistrationDialog,
    UserLoginDialog,
    ErrorDialog,
} from '../dialogs'

import DialogMenuItem from '../menu-items/dialog-menu-item'
import { useDispatch, useSelector } from 'react-redux'
import { setUser, clearUser } from '../../store/general-slice'
import { StompSessionProvider } from 'react-stomp-hooks'
import { useTranslation } from 'react-i18next'
import GameServerMembership from '../pages/game-servers-membership-page'
import { useApiRequest, useDialog } from '../hoc'
import { DialogKeys } from '../../constants'
import moment from 'moment'
import 'moment/locale/ru'

const AppName = 'Rust servers online'

export default function App() {
    const { t, i18n } = useTranslation()
    const [apiService] = useState(new ApiService())
    const { title, user } = useSelector(({ general: { title, user } }) => ({
        title,
        user,
    }))
    const dispatch = useDispatch()

    useEffect(() => {
        apiService.changeLocale(i18n.language)
        moment.locale(i18n.language)
    }, [apiService, i18n.language])

    useEffect(() => {
        apiService
            .getCurrentUser()
            .then(({ payload }) => {
                dispatch(setUser(payload))
            })
            .catch((error) => {
                if (error.status === 403) {
                    dispatch(clearUser())
                } else {
                    throw error
                }
            })
    }, [apiService, dispatch])

    useEffect(() => {
        if (title == null) {
            document.title = AppName
        } else {
            document.title = `${title} :: ${AppName}`
        }
    }, [title])

    const loginDialog = useDialog(DialogKeys.login, UserLoginDialog)

    return (
        <BrowserRouter>
            <ApiServiceContext.Provider value={apiService}>
                <StompSessionProvider url={'/ws'}>
                    <>
                        <Menu inverted>
                            <Container>
                                <Menu.Item header as={Link} to="/">
                                    {AppName}
                                </Menu.Item>
                                <Menu.Item as={Link} to="/own-servers">
                                    {t('i18n.Own-servers')}
                                </Menu.Item>

                                <Menu.Menu position="right">
                                    <Dropdown item text={t('i18n.User')}>
                                        <Dropdown.Menu>
                                            <Menu.Item
                                                as={Link}
                                                to="/users/preferences"
                                            >
                                                {t('i18n.User-preferences')}
                                            </Menu.Item>
                                            {user === null ? (
                                                <>
                                                    <DialogMenuItem
                                                        title={t(
                                                            'i18n.Register'
                                                        )}
                                                        dialogKey="registration"
                                                    />
                                                    <Dropdown.Divider />
                                                    <DialogMenuItem
                                                        title={t('i18n.Login')}
                                                        dialogKey="login"
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <Menu.Item
                                                        as={Link}
                                                        to="/users/game-servers-membership"
                                                    >
                                                        {t(
                                                            'i18n.Game-servers-membership'
                                                        )}
                                                    </Menu.Item>
                                                    <Menu.Item
                                                        as={Link}
                                                        to="/users/password-change"
                                                    >
                                                        {t(
                                                            'i18n.Password-change'
                                                        )}
                                                    </Menu.Item>
                                                    <LogoutDropdownItem />
                                                </>
                                            )}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Menu.Menu>
                            </Container>
                        </Menu>

                        <Grid padded>
                            <Grid.Row centered>
                                <Header dividing size="huge" as="h1">
                                    {title}
                                </Header>
                            </Grid.Row>
                            <Grid.Row centered>
                                <Routes>
                                    <Route path="/" element={<HomePage />} />
                                    <Route
                                        path="/own-servers"
                                        element={<OwnServersPage />}
                                    />
                                    <Route
                                        path="/servers/:gameServerUuid/*"
                                        element={<ServerPage />}
                                    />
                                    <Route
                                        path="/users/password-change"
                                        element={<UserPasswordChangePage />}
                                    />
                                    <Route
                                        path="/users/preferences"
                                        element={<UserPreferencesPage />}
                                    />
                                    {user ? (
                                        <>
                                            <Route
                                                path="/users/game-servers-membership"
                                                element={
                                                    <GameServerMembership />
                                                }
                                            />
                                        </>
                                    ) : null}

                                    <Route
                                        path="*"
                                        element={<NotFoundPage />}
                                    />
                                </Routes>
                            </Grid.Row>
                        </Grid>

                        <UserRegistrationDialog />
                        {loginDialog.component}
                        <ErrorDialog />
                    </>
                </StompSessionProvider>
            </ApiServiceContext.Provider>
        </BrowserRouter>
    )
}
