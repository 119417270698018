import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

const resources = {
    en: {
        translation: {
            'i18n.Home-page': 'Home page',
            'i18n.Profile': 'Profile',
            'i18n.User-preferences': 'User preferences',
            'i18n.User': 'User',
            'i18n.Register': 'Register',
            'i18n.Login': 'Login',
            'i18n.Server-title': 'Server title',
            'i18n.No-servers-found': 'No servers found.',
            'i18n.No-players-found': 'No players found.',
            'i18n.Own-servers': 'Own servers',
            'i18n.The-Server-was-not-connected': 'The server was not connected',
            'i18n.own-servers.Unauth-message':
                'To control your own servers you should to login.',
            'i18n.Add': 'Add',
            'i18n.Edit': 'Edit',
            'i18n.Delete': 'Delete',
            'i18n.Address': 'Address',
            'i18n.Port': 'Port',
            'i18n.Password': 'Password',
            'i18n.Description': 'Description',
            'i18n.Players-count': 'Players count',
            'i18n.The-server-was-deleted':
                'The server {{address}}:{{port}} was deleted.',
            'i18n.The-server-was-created':
                'The server {{address}}:{{port}} was created.',
            'i18n.The-server-was-saved':
                'The server {{address}}:{{port}} was saved.',
            'i18n.Server-delete-confirmation':
                'The server {{address}}:{{port}} will be irrevocably deleted. Are you sure?',
            'i18n.Yes': 'Yes',
            'i18n.No': 'No',
            'i18n.Close': 'Close',
            'i18n.Cancel': 'Cancel',
            'i18n.Save': 'Save',
            'i18n.Server-adding': 'Server adding',
            'i18n.Server-editing': 'Server editing',
            'i18n.Server-deleting': 'Server deleteing',
            'i18n.Logout': 'Logout',
            'i18n.Summary': 'Summary',
            'i18n.Online': 'Online',
            'i18n.Statistics': 'Statistics',
            'i18n.Chat': 'Chat',
            'i18n.Total-time-spent-on-the-server':
                'Total time spent on the server',
            'i18n.For-the-entire-period': 'For the entire period',
            'i18n.Since-the-last-wipe': 'Since the last wipe',
            'i18n.From-a-certain-date': 'From a certain date',
            'i18n.Player': 'Player',
            'i18n.Duration': 'Duration',
            'i18n.There-are-no-statistics-yet': 'There are no statistics yet',
            'i18n.There-are-no-messages-yet': 'There are no messages yet',
            'i18n.There-are-no-members-yet': 'There are no members yet',
            'i18n.Reset-password': 'Reset password',
            'i18n.Reset': 'Reset',
            'i18n.I-dont-remember-the-password':
                "I don't remember the password",
            'i18n.I-remember-the-password': 'I remember the password',
            'i18n.Login-success-message': 'Wellcome, {{nickname}}.',
            'i18n.Reset-password-success-message':
                "An letter with instructions on how to reset the password was sent to the user's email address.",
            'i18n.Email': 'Email',
            'i18n.Nickname': 'Nickname',
            'i18n.Password-repeat': 'Password repeat',
            'i18n.passwords-must-match': 'passwords must match',
            'i18n.Create-user-success-message':
                'You have successfully registered a new user. An password change link has been sent to your email address.',
            'i18n.Register-new-user': 'Registering a new user',
            'i18n.Language': 'Language',
            'i18n.Timezone': 'Timezone',
            'i18n.Last-time-was-online': 'Last time was online {{when}} ago',
            'i18n.Email-address-confirmation': 'Email address confirmation',
            'i18n.Token': 'Token',
            'i18n.Password-change': 'Password change',
            'i18n.Submit': 'Submit',
            'i18n.Password-has-been-changed-successfully':
                'Password has been changed successfully.',
            'i18n.intervalToString.days': 'd.',
            'i18n.intervalToString.hours': 'h.',
            'i18n.intervalToString.minutes': 'm.',
            'i18n.intervalToString.seconds': 's.',
            'i18n.Page-not-found': 'Page not found',
            'i18n.Page-not-found-text':
                'It seems that the link which you followed is not valid any more.',
            'i18n.Membership': 'Membership',
            'i18n.Send-invite': 'Send invite',
            'i18n.Game-servers-membership': 'Game servers membership',
            'i18n.You-are-not-a-member-of-any-game-server':
                'You are not a member of any game server.',
            'i18n.Accepted-membership': 'Accepted membership',
            'i18n.Invites': 'Invites',
            'i18n.No-invitations': 'No invites.',
            'i18n.There-is-no-confirmed-membership':
                'There is no confirmed membership.',
            'i18n.Notices': 'Notices',
            'i18n.Interval-between-notices':
                'Interval between notices (minutes)',
            'i18n.Is-enabled': 'Is enabled',
            'i18n.Settings': 'Settings',
            'i18n.You-dont-have-notices-yet': "You don't have notices yet.",
            'i18n.enabled': 'enabled',
            'i18n.disabled': 'disabled',
            'i18n.Notice-adding': 'Notice adding',
            'i18n.Notice-editing': 'Notice editing',
            'i18n.never': 'never',
            'i18n.news.GameServerNotices.date': '24.03.2024',
            'i18n.news.GameServerNotices.description':
                'Notices implemented! Now you can add a set of notices for your server and set the frequency of their appearance.',
        },
    },
    ru: {
        translation: {
            'i18n.Home-page': 'Домашняя страница',
            'i18n.Profile': 'Профиль',
            'i18n.User-preferences': 'Пользовательские предпочтения',
            'i18n.Own-servers': 'Собственные серверы',
            'i18n.The-Server-was-not-connected': 'Сервер не подключался',
            'i18n.User': 'Пользователь',
            'i18n.Register': 'Зарегистрироваться',
            'i18n.Login': 'Войти',
            'i18n.Server-title': 'Наименование сервера',
            'i18n.No-servers-found': 'Серверы не найдены',
            'i18n.No-players-found': 'Игроки не найдены.',
            'i18n.own-servers.Unauth-message':
                'Для того, чтобы управлять собственными серверами, Вам необходимо залогиниться.',
            'i18n.Add': 'Добавить',
            'i18n.Edit': 'Редактировать',
            'i18n.Delete': 'Удалить',
            'i18n.Address': 'Адрес',
            'i18n.Port': 'Порт',
            'i18n.Password': 'Пароль',
            'i18n.Description': 'Описание',
            'i18n.Players-count': 'Число игроков',
            'i18n.The-server-was-deleted':
                'Сервер {{address}}:{{port}} удален.',
            'i18n.The-server-was-created':
                'Сервер {{address}}:{{port}} создан.',
            'i18n.The-server-was-saved':
                'Сервер {{address}}:{{port}} сохранен.',
            'i18n.Server-delete-confirmation':
                'Сервер {{address}}:{{port}} будет безвозвратно удален. Вы уверены?',
            'i18n.Yes': 'Да',
            'i18n.No': 'Нет',
            'i18n.Close': 'Закрыть',
            'i18n.Cancel': 'Отмена',
            'i18n.Save': 'Сохранить',
            'i18n.Server-adding': 'Добавление сервера',
            'i18n.Server-editing': 'Редактирование сервера',
            'i18n.Server-deleting': 'Удаление сервера',
            'i18n.Logout': 'Разлогиниться',
            'i18n.Summary': 'Сводка',
            'i18n.Online': 'Онлайн',
            'i18n.Statistics': 'Статистика',
            'i18n.Chat': 'Чат',
            'i18n.Total-time-spent-on-the-server':
                'Общее время проведенное на сервере',
            'i18n.For-the-entire-period': 'За весь период',
            'i18n.Since-the-last-wipe': 'С момента последнего вайпа',
            'i18n.From-a-certain-date': 'С определенной даты',
            'i18n.Player': 'Игрок',
            'i18n.Duration': 'Длительность',
            'i18n.There-are-no-statistics-yet': 'Статистика пока отсутствует',
            'i18n.There-are-no-messages-yet': 'Сообщения пока отсутствуют',
            'i18n.There-are-no-members-yet': 'Члены пока отсутствуют',
            'i18n.Reset-password': 'Сброс пароля',
            'i18n.Reset': 'Сбросить',
            'i18n.I-dont-remember-the-password': 'Я не помню пароль',
            'i18n.I-remember-the-password': 'Я помню пароль',
            'i18n.Login-success-message': 'Добро пожаловать, {{nickname}}.',
            'i18n.Reset-password-success-message':
                'На почтовый адрес пользователя было отправлено письмо с инструкциями по сбросу пароля.',
            'i18n.Email': 'Электронная почта',
            'i18n.Nickname': 'Ник',
            'i18n.Password-repeat': 'Повтор пароля',
            'i18n.passwords-must-match': 'пароли дожны совпадать',
            'i18n.Create-user-success-message':
                'Вы успешно зарегистрировали нового пользователя. На ваш почтовый адрес была отправлена ссылка для смены пароля.',
            'i18n.Register-new-user': 'Регистрация нового пользователя',
            'i18n.Language': 'Язык',
            'i18n.Timezone': 'Временная зона',
            'i18n.Last-time-was-online':
                'Последний раз был онлайн {{when}} назад',
            'i18n.Email-address-confirmation':
                'Подтверждение адреса электронной почты',
            'i18n.Token': 'Токен',
            'i18n.Password-change': 'Смена пароля',
            'i18n.Submit': 'Подтвердить',
            'i18n.Password-has-been-changed-successfully':
                'Пароль успешно изменен.',
            'i18n.intervalToString.days': 'д.',
            'i18n.intervalToString.hours': 'ч.',
            'i18n.intervalToString.minutes': 'м.',
            'i18n.intervalToString.seconds': 'с.',
            'i18n.Page-not-found': 'Страница не найдена',
            'i18n.Page-not-found-text':
                'Кажется, что ссылка, по которой вы перешли, больше не действительна.',
            'i18n.Membership': 'Членство',
            'i18n.Send-invite': 'Отправить приглашение',
            'i18n.Game-servers-membership': 'Членство в игровых серверах',
            'i18n.You-are-not-a-member-of-any-game-server':
                'Вы не являетесь участником какого-либо игрового сервера.',
            'i18n.Accepted-membership': 'Принятое членство',
            'i18n.Invites': 'Приглашения',
            'i18n.No-invitations': 'Приглашения отсутствуют.',
            'i18n.There-is-no-confirmed-membership':
                'Подтвержденного членства нет.',
            'i18n.Notices': 'Объявления',
            'i18n.Interval-between-notices':
                'Интервал между объявлениями (в минутах)',
            'i18n.Is-enabled': 'Включено',
            'i18n.Settings': 'Настройки',
            'i18n.You-dont-have-notices-yet': 'У вас пока нет объявлений.',
            'i18n.enabled': 'активно',
            'i18n.disabled': 'неактивно',
            'i18n.never': 'никогда',
            'i18n.news.GameServerNotices.date': '24.03.2024',
            'i18n.news.GameServerNotices.description':
                'Реализованы объявления! Теперь вы можете добавить для своего сервера набор объявлений и задать периодичность их появления.',
        },
    },
}

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
        detection: {
            order: ['localStorage'],
            lookupLocalStorage: 'i18nextLng',
            caches: ['localStorage'],
        },
    })

export default i18n
