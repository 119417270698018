import { useEffect, useState } from 'react'
import { useSubscription } from 'react-stomp-hooks'
import { useParams } from 'react-router-dom'
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    Card,
    CardContent,
    Icon,
    Button,
} from 'semantic-ui-react'
import SemanticDatepicker from 'react-semantic-ui-datepickers'
import { Websocket } from '../../../constants'
import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone'
import { useApiRequest } from '../../hoc'

export default function Chat() {
    const { t, i18n } = useTranslation()

    const { gameServerUuid } = useParams()

    const apiChatMessages = useApiRequest(
        ({ getChatMessages }) => getChatMessages,
        { inProgress: true, payload: [] }
    )

    const [date, setDate] = useState(
        moment()
            .set('hour', 0)
            .set('minute', 0)
            .set('second', 0)
            .set('millisecond', 0)
    )

    const addDays = (daysCount) => {
        setDate(date.clone().add(daysCount, 'day'))
    }

    const updateMessages = () => {
        apiChatMessages.fetch({
            gameServerUuid,
            startDateTime: date,
            endDateTime: date.clone().add(1, 'day'),
        })
    }
    useEffect(updateMessages, [gameServerUuid, date])

    useSubscription(
        Websocket.Topics.Servers + '/' + gameServerUuid + '/chat',
        ({ body }) => {
            const chatMessage = JSON.parse(body)
            const createDateTime = new Date(chatMessage.createDateTime)
            createDateTime.setHours(0, 0, 0, 0)
            const filterDateTime = new Date(date)
            filterDateTime.setHours(0, 0, 0, 0)
            if (filterDateTime.getTime() === createDateTime.getTime()) {
                apiChatMessages.setPayload([
                    ...apiChatMessages.payload,
                    chatMessage,
                ])
            }
        }
    )

    const emptyRow = (
        <TableRow>
            <TableCell colSpan={3} fluid textAlign="center">
                {t('i18n.There-are-no-messages-yet')}
            </TableCell>
        </TableRow>
    )

    const drawRow = ({
        uuid,
        createDateTime,
        playerDisplayName,
        text,
        channel,
    } = {}) => {
        return (
            <TableRow key={uuid} warning={channel === 1}>
                <TableCell singleLine>
                    {moment(createDateTime).format('HH:mm:ss')}
                </TableCell>
                <TableCell singleLine>
                    {playerDisplayName.displayName}
                </TableCell>
                <TableCell fluid>{text}</TableCell>
            </TableRow>
        )
    }

    return (
        <Card fluid>
            <CardContent textAlign="center">
                <Button icon onClick={() => addDays(-1)}>
                    <Icon name="arrow left" />
                </Button>
                <SemanticDatepicker
                    clearable={false}
                    type="basic"
                    value={date.toDate()}
                    locale={i18n.language === 'ru' ? 'ru-RU' : 'en-US'}
                    clearOnSameDateClick={false}
                    onChange={(event, data) => {
                        setDate(moment(data.value))
                    }}
                    filterDate={(newDate) => {
                        return moment().isAfter(moment(newDate))
                    }}
                />
                <Button
                    icon
                    onClick={() => addDays(1)}
                    disabled={date.clone().add(1, 'day').isAfter(moment())}
                >
                    <Icon name="arrow right" />
                </Button>
                <Table striped size="small" compact basic="very">
                    <TableBody>
                        {apiChatMessages.payload.length > 0
                            ? apiChatMessages.payload.map(drawRow)
                            : emptyRow}
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    )
}
