import { useEffect } from 'react'
import { useApiRequest } from '../hoc'
import { Link } from 'react-router-dom'
import { useTitle } from '../hoc'
import {
    Container,
    Grid,
    Icon,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
} from 'semantic-ui-react'
import { useSubscription } from 'react-stomp-hooks'
import { Websocket } from '../../constants'
import { useTranslation } from 'react-i18next'
import News from '../news'

export default function HomePage() {
    const { t } = useTranslation()

    const apiGameServers = useApiRequest(
        ({ getGameServers }) => getGameServers,
        { payload: [], inProgress: true }
    )

    useEffect(apiGameServers.fetch, [])
    useSubscription(Websocket.Topics.Servers, apiGameServers.fetch)

    useTitle(t('i18n.Home-page'))

    const table = (
        <Table celled striped>
            <TableHeader>
                <TableHeaderCell>{t('i18n.Server-title')}</TableHeaderCell>
                <TableHeaderCell textAlign="center">
                    {t('i18n.Players-count')}
                </TableHeaderCell>
            </TableHeader>
            <TableBody>
                {apiGameServers.inProgress ||
                apiGameServers.payload.length === 0 ? (
                    <TableRow>
                        <TableCell colSpan={2}>
                            <div className="center aligned">
                                {apiGameServers.inProgress ? (
                                    <div className="ui active centered inline loader"></div>
                                ) : apiGameServers.payload.length === 0 ? (
                                    <>{t('i18n.No-servers-found')}</>
                                ) : null}
                            </div>
                        </TableCell>
                    </TableRow>
                ) : (
                    apiGameServers.payload.map(
                        (
                            {
                                uuid,
                                gameServerInfo: {
                                    hostname,
                                    playersCount,
                                    maxPlayersCount,
                                    online,
                                },
                            } //FIXME: Вынести в компонент
                        ) => (
                            <TableRow key={uuid}>
                                <TableCell>
                                    <Icon
                                        name="power"
                                        color={online ? 'green' : 'red'}
                                    />
                                    <Link to={`/servers/${uuid}`}>
                                        {hostname}
                                    </Link>
                                </TableCell>
                                <TableCell textAlign="center">
                                    {playersCount} / {maxPlayersCount}
                                </TableCell>
                            </TableRow>
                        )
                    )
                )}
            </TableBody>
        </Table>
    )

    return (
        // <Container text>
        <Grid columns={'2'} container>
            <Grid.Row>
                <Grid.Column width={4}>
                    <News />
                </Grid.Column>
                <Grid.Column>{table}</Grid.Column>
                <Grid.Column width={4}></Grid.Column>
            </Grid.Row>
        </Grid>
        // </Container>
    )
}
