import { useSearchParams } from 'react-router-dom'
import { useTitle } from '../hoc'
import { useContext, useEffect, useState } from 'react'
import { ApiServiceContext } from '../../contexts'
import { Container, Header, Icon, Message, Segment } from 'semantic-ui-react'
import { Button, Form, Modal } from 'semantic-ui-react'
import { FormFieldWithError } from '../forms'
import ErrorMessage from '../error-message'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

export default function UserPasswordChangePage() {
    const { t } = useTranslation()
    const { user } = useSelector(({ general: { user } }) => ({
        user,
    }))

    const [searchParams, setSearchParams] = useSearchParams()
    const userTokenUuid = searchParams.get('userTokenUuid')
    if (user && userTokenUuid) {
        setSearchParams(undefined)
    }

    const { userChangePassword } = useContext(ApiServiceContext)
    const [state, setState] = useState({
        inProgress: false,
        success: null,
        error: null,
        password: '',
        passwordRepeat: '',
    })

    const doPasswordChange = () => {
        setState({ ...state, inProgress: true, error: null })
        userChangePassword(state.password, userTokenUuid)
            .then(() => {
                setState({
                    ...state,
                    error: null,
                    inProgress: false,
                    success: true,
                })
            })
            .catch((error) => {
                setState({ ...state, error, inProgress: false, success: false })
            })
    }

    useTitle(t('i18n.Password-change'))

    const getFieldValue = (field) => {
        return state[field]
    }

    const setFieldValue = (field, value) => {
        return setState({ ...state, error: null, [field]: value })
    }

    const fieldMessages =
        state.error && state.error.response
            ? { ...state.error.response.fieldMessages }
            : {}

    let preValidated = true

    if (state.password === '') {
        preValidated = false
    }

    if (state.password !== state.passwordRepeat) {
        fieldMessages['passwordRepeat'] = t('i18n.passwords-must-match')
        preValidated = false
    }

    return (
        <Container text>
            {state.success ? (
                <Message positive>
                    <Message.Content>
                        {t('i18n.Password-has-been-changed-successfully')}
                    </Message.Content>
                </Message>
            ) : (
                <Segment>
                    <Form>
                        <FormFieldWithError
                            label={t('i18n.Password')}
                            icon="shield alternate"
                            disabled={state.inProgress}
                            getValue={getFieldValue}
                            setValue={setFieldValue}
                            error={state.error}
                            name="password"
                            type="password"
                        />
                        <FormFieldWithError
                            label={t('i18n.Password-repeat')}
                            icon="shield alternate"
                            disabled={state.inProgress}
                            getValue={getFieldValue}
                            setValue={setFieldValue}
                            error={state.error}
                            name="passwordRepeat"
                            type="password"
                        />
                        {state.error ? (
                            <ErrorMessage error={state.error} />
                        ) : null}
                        <Button
                            positive
                            disabled={state.inProgress || !preValidated}
                            onClick={doPasswordChange}
                        >
                            {t('i18n.Submit')}
                        </Button>
                    </Form>
                </Segment>
            )}
        </Container>
    )
}
