import { Segment } from 'semantic-ui-react'
import SendInvite from './send-invite'
import MemersList from './member-list'

export default function Members({ gameServer }) {
    return (
        <Segment textAlign="center">
            <SendInvite gameServer={gameServer} />
            <MemersList gameServer={gameServer} />
        </Segment>
    )
}
