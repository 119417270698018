import React from 'react'
import { Button, Modal } from 'semantic-ui-react'
import { clearErrors } from '../../store/general-slice'
import { useDispatch, useSelector } from 'react-redux'
import ErrorMessage from '../error-message'

export default function ErrorDialog({ title = 'Ошибка', text }) {
    const { open, errors } = useSelector(
        ({
            general: {
                errors,
                openedDialogs: { error: open = false },
            },
        }) => ({ errors, open })
    )
    const dispatch = useDispatch()
    const onClose = () => dispatch(clearErrors())

    return (
        <Modal open={open} size="mini">
            <Modal.Header>{title}</Modal.Header>
            <Modal.Content>
                {errors.map((error) => (
                    <ErrorMessage error={error} />
                ))}
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onClose}>OK</Button>
            </Modal.Actions>
        </Modal>
    )
}
