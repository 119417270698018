import { closeDialog } from '../../store/general-slice'
import { useSelector, useDispatch } from 'react-redux'

const withDialog = (dialogKey) => (WrappedComponent) => {
    return (props) => {
        const { open, ...otherProps } = useSelector(
            ({ general: { openedDialogs } }) => {
                return {
                    open: dialogKey in openedDialogs,
                    ...(openedDialogs[dialogKey]
                        ? openedDialogs[dialogKey]
                        : {}),
                }
            }
        )

        const dispatch = useDispatch()

        return open ? (
            <WrappedComponent
                {...props}
                {...otherProps}
                open={true}
                onClose={() => dispatch(closeDialog({ dialogKey }))}
            />
        ) : null
    }
}

export default withDialog
