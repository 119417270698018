import {
    Container,
    Select,
    Table,
    TableCell,
    TableRow,
} from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { useTitle } from '../hoc'

function LanguageComponent() {
    const { i18n } = useTranslation()

    const options = [
        { value: 'ru', text: 'Russia' },
        { value: 'en', text: 'English' },
    ]

    return (
        <Select
            fluid
            value={i18n.language}
            options={options.map((item) => ({
                ...item,
                onClick: () => i18n.changeLanguage(item.value),
            }))}
        />
    )
}

export default function UserPreferencesPage() {
    const { t } = useTranslation()

    useTitle(t('i18n.User-preferences'))

    return (
        <Container text>
            <Table basic="very" celled singleLine compact fixed>
                <TableRow>
                    <TableCell>{t('i18n.Language')}:</TableCell>
                    <TableCell style={{ overflow: 'visible' }}>
                        <LanguageComponent />
                    </TableCell>
                </TableRow>
            </Table>
        </Container>
    )
}
