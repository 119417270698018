function intervalToString(totalMSecs, t) {
    const lZero = (s) => {
        if (s < 10) return '0' + s
        else return s
    }

    // const secs = Math.floor(totalMSecs / 1000) % 60
    const mins = Math.floor(totalMSecs / (1000 * 60)) % 60
    const hours = Math.floor(totalMSecs / (1000 * 60 * 60)) % 24
    const days = Math.floor(totalMSecs / (1000 * 60 * 60 * 24))

    let total = ''
    if (days > 0 || total.length > 0) {
        if (total.length > 0) {
            total = total + ' '
        }
        total = total + lZero(days) + t('i18n.intervalToString.days')
    }
    if (hours > 0 || total.length > 0) {
        if (total.length > 0) {
            total = total + ' '
        }
        total = total + lZero(hours) + t('i18n.intervalToString.hours')
    }
    if (mins > 0 || total.length > 0) {
        if (total.length > 0) {
            total = total + ' '
        }
        total = total + lZero(mins) + t('i18n.intervalToString.minutes')
    }
    // if (secs > 0 || total.length > 0) {
    //     if (total.length > 0) {
    //         total = total + ' '
    //     }
    //     total = total + lZero(secs) + t('i18n.intervalToString.seconds')
    // }
    return total
}

export { intervalToString }
