import { Header, Icon, Message } from 'semantic-ui-react'
import PropTypes from 'prop-types'

export default function ErrorMessage({ error, icon, header }) {
    let messages
    if (error.payload) {
        messages = error.payload.messages
    } else {
        messages = [error.status]
    }

    let content
    if (messages.length === 1) {
        content = messages[0]
    } else {
        content = (
            <ul>
                {messages.map((item) => (
                    <li>{item}</li>
                ))}
            </ul>
        )
    }

    return messages.length === 0 ? null : (
        <Message negative icon={icon !== undefined && icon !== null}>
            {icon ? <Icon name={icon} /> : null}
            <Message.Content>
                {header ? <Header>{header}</Header> : null}
                {content}
            </Message.Content>
        </Message>
    )
}

ErrorMessage.propTypes = {
    icon: PropTypes.string,
    header: PropTypes.string,
    error: PropTypes.shape({
        status: PropTypes.number.isRequired,
        response: PropTypes.shape({
            fieldMessages: PropTypes.objectOf(PropTypes.string).isRequired,
            messages: PropTypes.arrayOf(PropTypes.string).isRequired,
        }),
    }),
}
